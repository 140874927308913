import { commonFeatureFlags, CommonFeatureFlags } from "../../../common/constants/CommonFeatureFlags";
import { commonEnvironment } from "./environment.common";

export const environment = {
    ...commonEnvironment,
    production: true,
    environment: "prod",
    redirectUrl: "https://jobscopingtool.findex.com.au",
    backendUrl: "https://5srj8qsen6.execute-api.ap-southeast-2.amazonaws.com/prod",
    locationsUrl: "https://prod.location.findex.solutions",
    loggingLevel: commonEnvironment.loggingLevels.info,
    notification: {
        endpoint: "wss://k37tzi9zzb.execute-api.ap-southeast-2.amazonaws.com/prod",
        channel: "prod",
    },
    sentry: {
        ...commonEnvironment.sentry,
        enabled: true,
    },
    documentServiceUrl: "https://ipyvmpym10.execute-api.ap-southeast-2.amazonaws.com/prod",
    emailPreviewEndpoint: "https://wv4sldlat2.execute-api.ap-southeast-2.amazonaws.com/prod/email/preview",
    azureAD: {
        ...commonEnvironment.azureAD,
        clientID: "b6051d1c-f8fd-48a2-9b8f-c3790a0d100b",
    },
    taskx: "https://taskx.findex.com.au",
    crm: "https://crm.findex.com.au/s/",
    googleAnalytics: {
        id: "UA-114939185-11",
    },
    hotjar: {
        id: "2488272",
    },
    constants: {
        DEBOUNCE_TIME_MS: 500,
        WS_RECONNECT_INTERVAL: 2000,
        SALESFORCE_LINK_PREFIX: "https://crm.findex.com.au/s/opportunity/",
        SALESFORCE_LINK_SUFFIX: "",
    },
    featureFlags: {
        [CommonFeatureFlags.YEARLESS_SCOPES]: commonFeatureFlags.prod.yearlessScopes,
    },
};
